import React, { useState, useEffect } from 'react';
import { ReactComponent as Logo } from '../assets/images/logo-mobile.svg';

const LogoSplash = () => {

    const [fadeProp, setFadeProp] = useState({
        fade: 'fade-out',
    });

    useEffect(() => {
        setTimeout(() => {
            setFadeProp({
                fade: 'fade-in'
            })
        }, 200);
        setTimeout(() => {
            setFadeProp({
                fade: 'fade-out'
            })
        }, 2000);

        return () => { }
    }, [])

    return (
        <div className={fadeProp.fade += ' logo-splash-container'}>
            <div className='logo-container'>
                <Logo />
            </div>
            <div className='coming-soon-container'>
                <p>Coming soon</p>
            </div>
        </div>
    );
};

export default LogoSplash;