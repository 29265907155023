import React from 'react';
import LogoSplash from '../components/LogoSplash';
import MainSplash from '../components/MainSplash';
import '../scripts/circle';
import { useMediaQuery } from 'react-responsive'

const Home = () => {

    const isTabletOrDesktop = useMediaQuery({ query: '(min-width: 1181px)' })

    return (
        <div>
            <LogoSplash />
            <MainSplash />
            {isTabletOrDesktop &&
                <span id="circle" className="circle"></span>
            }

        </div>
    );
};

export default Home;