import React, { useState, useEffect } from 'react';
import Mailto from './Mailto';
import { useMediaQuery } from 'react-responsive'
import { ReactComponent as Logo } from '../assets/images/logo-mobile.svg';



const MainSplash = () => {

    const isMobile = useMediaQuery({ query: '(max-width: 1180px)' })
    const isTabletOrDesktop = useMediaQuery({ query: '(min-width: 1181px)' })

    const [fadeProp, setFadeProp] = useState({
        fade: 'fade-out',
    });

    useEffect(() => {

        setTimeout(() => {
            setFadeProp({
                fade: 'fade-in'
            })
        }, 3200);


        return () => { }
    }, [])

    return (
        <div>
            {isTabletOrDesktop &&
                <div className={fadeProp.fade += ' main-splash-container'}>
                    <div className='main-splash-header'>
                        <div className="main-splash-header-left">
                            <span>Coming soon</span>
                            <span>contact@madestudio.fr</span>
                        </div>
                        <div className="main-splash-header-right">
                            <Mailto email="contact@madestudio.fr">
                                Un projet, une idée ? Discutons-en !
                            </Mailto>
                        </div>
                    </div>

                    <div className='main-splash-content'>
                        <div className='main-splash-content-left'>
                            <ul>
                                <a href="https://www.instagram.com/madestudio_fr/?hl=fr" target="_blank" rel="noreferrer"><li>Instagram</li></a>
                                <a href="https://www.linkedin.com/company/made-studio-fr/" target="_blank" rel="noreferrer"><li>Linkedin</li></a>
                                <a href="https://www.facebook.com/Made-Studio-102438222471231" target="_blank" rel="noreferrer"><li>Facebook</li></a>
                            </ul>
                            <p>Notre site web est actuellement en cours de construction...</p>
                        </div>
                        <div className="main-splash-content-right">
                            <div className='text-container'>
                                <p>Nous sommes un studio <span className='ivymode-bold-italic'>indépendant</span></p>
                                <p>spécialisé dans la conception et le</p>
                                <p>déploiement de <span className='ivymode-bold-italic'>solutions digitales</span></p>
                                <p><span className='ivymode-bold-italic'>uniques</span> et <span className='ivymode-bold-italic'>sur mesure.</span></p>
                            </div>
                        </div>
                    </div>

                </div>
            }

            {isMobile &&
                <div className={fadeProp.fade += ' main-splash-mobile-container'}>

                    <div className='main-splash-mobile-header'>
                        <Logo />
                    </div>

                    <div className='main-splash-mobile-content'>
                        <div className='text-container'>
                            <p>Nous sommes un studio</p>
                            <p><span className='ivymode-bold-italic'>indépendant</span> spécialisé dans la</p>
                            <p>conception et le déploiement de</p>
                            <p><span className='ivymode-bold-italic'>solutions digitales uniques</span></p>
                            <p>et <span className='ivymode-bold-italic'>sur mesure.</span></p>
                        </div>
                        <div className='mailto-container'>
                            <Mailto email="contact@madestudio.fr">
                                Un projet, une idée ? Discutons-en !
                            </Mailto>
                        </div>
                    </div>

                    <div className='main-splash-mobile-footer'>
                        <ul>
                            <a href="https://www.facebook.com/Made-Studio-102438222471231" target="_blank" rel="noreferrer"><li>Facebook</li></a>
                            <a href="https://www.linkedin.com/company/made-studio-fr/" target="_blank" rel="noreferrer"><li>Linkedin</li></a>
                            <a href="https://www.instagram.com/madestudio_fr/?hl=fr" target="_blank" rel="noreferrer"><li>Instagram</li></a>
                        </ul>
                    </div>

                </div>
            }
        </div>




    );
};

export default MainSplash;