import $ from 'jquery'

var mouseX = 0, mouseY = 0;
var xp = 0, yp = 0;

$(document).mousemove(function (e) {
    mouseX = e.pageX - 5;
    mouseY = e.pageY - 5;
});

setInterval(function () {
    xp += ((mouseX - xp) / 6);
    yp += ((mouseY - yp) / 6);
    $("#circle").css({ left: xp + 'px', top: yp + 'px' });
}, 20);
